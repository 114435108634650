import { jsx as _jsx } from "react/jsx-runtime";
import { Backdrop as MuiBackdrop, CircularProgress } from '@mui/material';
var Backdrop = function (_a) {
    var isLoading = _a.isLoading;
    return (_jsx(MuiBackdrop, { sx: {
            background: 'rgba(0, 0, 0, 0.2)',
            color: '#fff',
            zIndex: function (theme) { return theme.zIndex.drawer + 1; }
        }, open: isLoading, children: _jsx(CircularProgress, { color: "inherit" }) }));
};
export default Backdrop;
