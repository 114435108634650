var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from '@mui/material';
export function Loading(_a) {
    var title = _a.title, sx = _a.sx, _b = _a.size, size = _b === void 0 ? 40 : _b;
    return (_jsxs(Box, { sx: __assign({ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }, sx), children: [_jsx(CircularProgress, { size: size }), " ", title] }));
}
