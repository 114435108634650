import { jsx as _jsx } from "react/jsx-runtime";
import { ListItemAvatar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
export var AvatarItem = function (_a) {
    var givenName = _a.givenName, familyName = _a.familyName, sx = _a.sx;
    var getInitials = function (firstName, lastName) {
        if (firstName && lastName) {
            return (firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase());
        }
        return 'AA';
    };
    return (_jsx(ListItemAvatar, { sx: sx, children: _jsx(Avatar, { sx: { bgcolor: '#027E87', fontWeight: 'bold' }, children: getInitials(givenName, familyName) }) }));
};
