var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler } from './api';
export var accessKeyV2Api = createApi({
    reducerPath: 'accessKeyV2Api',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    tagTypes: ['AccessKeyV2', 'AccessKeysV2', 'Assets', 'AssetPermissions'],
    endpoints: function (builder) { return ({
        getAssets: builder.query({
            query: function () { return ({
                url: "/api/v1/access-key-assets"
            }); },
            providesTags: [{ type: 'Assets' }]
        }),
        getAssetPermissions: builder.query({
            query: function (id) { return ({
                url: "/api/v1/access-key-assets/".concat(id, "/permissions"),
                responseHandler: function (response) {
                    return responseHandler(response, 'Assets-Permissions');
                }
            }); },
            providesTags: function (_result, _error, id) { return [{ type: 'AssetPermissions', id: id }]; }
        }),
        getAccessKeys: builder.query({
            query: function () { return ({
                url: 'api/v2/access-keys',
                responseHandler: function (response) { return responseHandler(response, 'AccessKeys'); }
            }); },
            providesTags: ['AccessKeysV2']
        }),
        getAccessKey: builder.query({
            query: function (id) { return ({
                url: "api/v2/access-keys/".concat(id),
                responseHandler: function (response) { return responseHandler(response, 'AccessKey'); }
            }); },
            providesTags: ['AccessKeyV2']
        }),
        createAccessKey: builder.mutation({
            query: function (key) { return ({
                url: "api/v2/access-keys",
                method: 'POST',
                body: __assign({}, key),
                responseHandler: function (response) {
                    return responseHandler(response, 'Create AccessKey');
                }
            }); },
            invalidatesTags: ['AccessKeyV2', 'AccessKeysV2']
        }),
        deleteAccessKey: builder.mutation({
            query: function (id) { return ({
                url: "api/v2/access-keys/".concat(id),
                method: 'DELETE',
                responseHandler: function (response) {
                    return responseHandler(response, 'Delete AccessKey');
                }
            }); },
            invalidatesTags: ['AccessKeysV2']
        }),
        updateAccessKey: builder.mutation({
            query: function (_a) {
                var id = _a.id, request = _a.request;
                return ({
                    url: "api/v2/access-keys/".concat(id),
                    method: 'PUT',
                    body: __assign({}, request),
                    responseHandler: function (response) {
                        return responseHandler(response, 'Update AccessKey');
                    }
                });
            },
            invalidatesTags: ['AccessKeysV2', 'AccessKeyV2']
        }),
        regenerateAccessKey: builder.mutation({
            query: function (_a) {
                var id = _a.id, extendedTime = _a.extendedTime;
                return ({
                    url: "api/v1/access-keys/".concat(id, "/regenerate"),
                    method: 'PATCH',
                    body: {
                        extendedTime: extendedTime
                    },
                    responseHandler: function (response) {
                        return responseHandler(response, 'Update AccessKey');
                    }
                });
            },
            transformResponse: function (response) {
                return response.accessKey;
            },
            invalidatesTags: ['AccessKeysV2', 'AccessKeyV2']
        })
    }); }
});
export var useGetAssetsQuery = accessKeyV2Api.useGetAssetsQuery, useGetAssetPermissionsQuery = accessKeyV2Api.useGetAssetPermissionsQuery, useLazyGetAssetPermissionsQuery = accessKeyV2Api.useLazyGetAssetPermissionsQuery, useCreateAccessKeyMutation = accessKeyV2Api.useCreateAccessKeyMutation, useGetAccessKeysQuery = accessKeyV2Api.useGetAccessKeysQuery, useGetAccessKeyQuery = accessKeyV2Api.useGetAccessKeyQuery, useDeleteAccessKeyMutation = accessKeyV2Api.useDeleteAccessKeyMutation, useUpdateAccessKeyMutation = accessKeyV2Api.useUpdateAccessKeyMutation, useRegenerateAccessKeyMutation = accessKeyV2Api.useRegenerateAccessKeyMutation;
