import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import Auth from './Auth';
import { BasePage } from './BasePage';
import { useAuthentication } from './common/hooks/authentication';
var UserPage = lazy(function () { return import('./views/Users/UserPage'); });
var TagView = lazy(function () { return import('./views/Tags/components/organisms/TagView'); });
var AccessKeyPage = lazy(function () { return import('./views/Developer/components/AccessKeyPage'); });
var AdminListUsers = lazy(function () { return import('./views/Admin/Users/components/organisms/AdminListUserView'); });
var AdminInviteUser = lazy(function () { return import('./views/Admin/Users/components/organisms/AdminUserAddView'); });
var AdminEditUser = lazy(function () { return import('./views/Admin/Users/components/organisms/AdminEditUserView'); });
var ListConnections = lazy(function () { return import('./views/Connections/components/organisms/ListConnectionsView'); });
var EditConnection = lazy(function () { return import('./views/Connections/EditConnectionPage'); });
var CreateConnection = lazy(function () { return import('./views/Connections/CreateConnectionPage'); });
var NotFoundPage = lazy(function () { return import('./components/templates/NotFoundPage'); });
export default function Application() {
    var _a = useAuthentication(), login = _a.login, isPricer = _a.isPricer, isPartnerTenant = _a.isPartnerTenant;
    var isAuthenticated = useAuth0().isAuthenticated;
    useEffect(function () {
        login();
    }, [isAuthenticated, login]);
    return (_jsx(Routes, { children: _jsxs(Route, { path: "/", element: _jsx(BasePage, {}), children: [_jsxs(Route, { path: "access-keys", element: _jsx(Auth, { hasPermission: isPartnerTenant }), children: [_jsx(Route, { path: "", element: _jsx(AccessKeyPage, {}), index: true }), _jsx(Route, { path: ":id", element: _jsx(AccessKeyPage, {}) }), _jsx(Route, { path: "add", element: _jsx(AccessKeyPage, {}) })] }), _jsxs(Route, { path: "users", element: _jsx(Auth, { hasPermission: isPartnerTenant }), children: [_jsx(Route, { path: "", element: _jsx(UserPage, {}), index: true }), _jsx(Route, { path: ":id", element: _jsx(UserPage, {}) }), _jsx(Route, { path: "invite", element: _jsx(UserPage, {}) })] }), _jsxs(Route, { path: "tags", element: _jsx(Auth, { hasPermission: isPartnerTenant }), children: [_jsx(Route, { path: "", element: _jsx(TagView, {}), index: true }), _jsx(Route, { path: ":id", element: _jsx(TagView, {}) }), _jsx(Route, { path: "create", element: _jsx(TagView, {}) })] }), _jsxs(Route, { path: "admin/users", element: _jsx(Auth, { hasPermission: function () { return isPricer; } }), children: [_jsx(Route, { path: "", element: _jsx(AdminListUsers, {}) }), _jsx(Route, { path: ":id", element: _jsx(AdminEditUser, {}) }), _jsx(Route, { path: "invite", element: _jsx(AdminInviteUser, {}) })] }), _jsxs(Route, { path: "/admin/connections", element: _jsx(Auth, { hasPermission: function () { return isPricer; } }), children: [_jsx(Route, { path: "", element: _jsx(ListConnections, {}) }), _jsx(Route, { path: ":id", element: _jsx(EditConnection, {}) }), _jsx(Route, { path: "create", element: _jsx(CreateConnection, {}) })] }), _jsx(Route, { path: "/404", element: _jsx(NotFoundPage, {}) }), _jsx(Route, { path: "*", element: _jsx(NotFoundPage, {}) })] }) }));
}
