import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useGetProfileQuery } from '../../api/profileApi';
import { useAuthentication } from '../../common/hooks/authentication';
import { SearchBar } from '../atoms/SearchBar';
var getInitials = function (name) {
    var initials = name
        .trim()
        .split(/[\s-]/)
        .map(function (n) { return n[0]; });
    return initials.length > 1
        ? initials[0].toUpperCase() + initials[1].toUpperCase()
        : initials[0].toUpperCase();
};
var displayImage = function (tenant) {
    return tenant.image ? (_jsx(Avatar, { sx: { marginX: '1rem' }, src: "".concat(tenant.image) })) : (_jsx(Avatar, { sx: {
            marginX: '1rem',
            fontWeight: 'bold',
            color: '#107C10',
            bgcolor: '#DAEADA'
        }, children: getInitials(tenant.name) }));
};
export var TenantSelector = function (_a) {
    var onClick = _a.onClick;
    var profile = useGetProfileQuery().data;
    var activeTenant = useAuthentication().tenant;
    var _b = useState(''), search = _b[0], setSearch = _b[1];
    var filteredTenants = ((profile === null || profile === void 0 ? void 0 : profile.tenants) || []).filter(function (tenant) {
        return tenant.name.toLowerCase().startsWith(search.toLowerCase());
    });
    return (_jsxs(Box, { width: '100%', height: '60rem', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingX: '8rem', paddingY: '5rem', children: [_jsx(Typography, { marginBottom: '5rem', fontSize: '1.375rem', marginRight: '.5rem', fontWeight: 'bold', color: '#505050', children: "Tenant List" }), _jsx(SearchBar, { maxWidth: "28.75rem", onSearch: function (search) { return setSearch(search); }, placeholder: 'Search Tenant', sx: { marginBottom: '3rem' } }), _jsx(Grid, { container: true, columnSpacing: 2.5, rowSpacing: 2, overflow: 'auto', children: filteredTenants.map(function (tenant, index) { return (_jsx(Grid, { item: true, xs: 12, md: 6, lg: 3, children: _jsxs(Box, { display: 'flex', alignItems: 'center', onClick: function () { return onClick(tenant.id); }, sx: {
                            height: '3.5rem',
                            border: "1px solid ".concat(tenant.id === activeTenant ? '#1A7C10' : '#C6C6C6'),
                            borderRadius: '.5rem',
                            cursor: 'pointer',
                            bgcolor: "".concat(tenant.id === activeTenant ? '#F2F8F3' : '#FFFFFF')
                        }, children: [displayImage(tenant), _jsx(Typography, { flex: 1, fontWeight: 'bold', color: '#505050', children: tenant.name })] }) }, index)); }) })] }));
};
