var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';
import Close from '../../common/assets/close.svg';
import { Loading } from '../atoms/Loading';
export var ModalView = function (_a) {
    var children = _a.children, isOpen = _a.isOpen, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, onClose = _a.onClose, _c = _a.maxWidth, maxWidth = _c === void 0 ? '56.25rem' : _c, _d = _a.maxHeight, maxHeight = _d === void 0 ? '56.25rem' : _d, sx = _a.sx;
    var fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    return (_jsx(Modal, { onClose: function () { return onClose(); }, open: isOpen, sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }, children: _jsxs(Box, { sx: __assign({ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: "".concat(fullScreen ? '100%' : maxWidth), maxHeight: "".concat(fullScreen ? '100vh' : maxHeight), boxShadow: 24, p: fullScreen ? 0 : 1, backgroundColor: '#F3F8F8', border: "".concat(fullScreen ? 'none' : '1px solid #707070'), borderRadius: "".concat(fullScreen ? 'none' : '20px'), outline: 'none', bgcolor: '#FFFFFF', position: 'relative' }, sx), children: [_jsx(Box, { position: 'absolute', top: '2.875rem', right: '3.375rem', children: _jsx(IconButton, { sx: { padding: '0px', bgcolor: '#FFF' }, onClick: function () { return onClose(); }, children: _jsx(Close, {}) }) }), _jsx(Box, { sx: {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflow: 'auto'
                    }, children: isLoading ? _jsx(Loading, {}) : children })] }) }));
};
