import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useAuthentication } from './common/hooks/authentication';
import { IamIcon } from './components/atoms/IamIcon';
import { Loading } from './components/atoms/Loading';
import { AppMenu } from './components/organisms/AppMenu';
import { useAppSelector } from './store/store';
var Page = function () {
    var token = useAppSelector(function (store) { return store.auth.token; });
    return (_jsx(Box, { display: 'flex', flexDirection: 'column', height: '100vh', position: 'relative', marginLeft: '3.75rem', children: token ? (_jsxs(_Fragment, { children: [_jsx(IamIcon, {}), _jsx(AppMenu, {}), _jsx(Suspense, { children: _jsx(Outlet, {}) })] })) : (_jsx(Loading, { sx: { height: '100vh', width: '100%' } })) }));
};
var Component = withAuthenticationRequired(Page);
export var BasePage = function () {
    var isPricerTenant = useAuthentication().isPricerTenant;
    var token = useAppSelector(function (store) { return store.auth.token; });
    var navigate = useNavigate();
    useEffect(function () {
        if (token) {
            if (window.location.pathname === '/') {
                isPricerTenant() ? navigate('/admin/users') : navigate('/users');
            }
        }
    }, [isPricerTenant, navigate, token]);
    return _jsx(Component, {});
};
