import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon } from '@mui/material';
import Iam from '../../common/assets/iam.svg';
export var IamIcon = function () {
    return (_jsx(SvgIcon, { sx: {
            width: '3.4rem',
            height: '3.4rem',
            zIndex: -1,
            position: 'absolute',
            right: '5rem',
            top: '1.5rem'
        }, viewBox: "0 0 55 55", children: _jsx(Iam, {}) }));
};
