import { jsx as _jsx } from "react/jsx-runtime";
import { Auth0Provider } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig } from '../common/config';
export var Auth0Context = function (_a) {
    var _b;
    var children = _a.children;
    var navigate = useNavigate();
    var queryParams = useSearchParams()[0];
    var paramTenantId = queryParams.get('tenantId');
    var tenantFromStorage = localStorage.getItem('currentTenant');
    useEffect(function () {
        if (paramTenantId && tenantFromStorage) {
            if (tenantFromStorage !== paramTenantId) {
                localStorage.setItem('currentTenant', paramTenantId);
            }
        }
    }, [paramTenantId, tenantFromStorage]);
    var onRedirectCallback = function (appState) {
        navigate((appState && appState.returnTo) || window.location.pathname);
    };
    return (_jsx(Auth0Provider, { domain: getConfig().authentication.domain, clientId: getConfig().authentication.clientId, onRedirectCallback: onRedirectCallback, authorizationParams: {
            audience: getConfig().authentication.audience,
            redirect_uri: window.location.origin,
            pricerTenantId: (_b = paramTenantId !== null && paramTenantId !== void 0 ? paramTenantId : tenantFromStorage) !== null && _b !== void 0 ? _b : undefined
        }, children: children }));
};
