import { isRejectedWithValue } from '@reduxjs/toolkit';
import { notification } from '../common/Notifications';
var ignoredActions = ['getRoles', 'getUsersOverview'];
var instanceOfResponseError = function (object) {
    return typeof object === 'object' && object !== null && 'data' in object;
};
var hasEndpointName = function (object) {
    return (typeof object === 'object' && object !== null && 'endpointName' in object);
};
export var rtkQueryErrorLogger = function () { return function (next) { return function (action) {
    if (isRejectedWithValue(action)) {
        if (instanceOfResponseError(action.payload)) {
            if (hasEndpointName(action.meta.arg)) {
                var endpointName = action.meta.arg.endpointName;
                if (ignoredActions.includes(endpointName)) {
                    return next(action);
                }
            }
            notification.error(action.payload.data);
        }
    }
    return next(action);
}; }; };
