var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
export var SearchBar = function (_a) {
    var onSearch = _a.onSearch, _b = _a.delay, delay = _b === void 0 ? 100 : _b, placeholder = _a.placeholder, _c = _a.maxWidth, maxWidth = _c === void 0 ? '43.687rem' : _c, _d = _a.width, width = _d === void 0 ? '100%' : _d, sx = _a.sx;
    var _e = useState(''), search = _e[0], setSearch = _e[1];
    var delayedSearch = useDebounce(search, delay)[0];
    useEffect(function () {
        onSearch(delayedSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delayedSearch]);
    return (_jsx(Box, { sx: __assign({ display: 'flex', alignItems: 'baseline', width: '100%', marginBottom: '1.282rem' }, sx), children: _jsx(TextField, { sx: {
                maxWidth: maxWidth,
                width: width
            }, value: search, placeholder: placeholder, onChange: function (e) { return setSearch(e.target.value); }, InputProps: {
                startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(SearchIcon, {}) })),
                endAdornment: search && (_jsx(InputAdornment, { position: "end", children: _jsx(Box, { sx: { display: 'flex', flexDirection: 'row' }, children: _jsx(IconButton, { "aria-label": "clear search word", onClick: function () { return setSearch(''); }, children: _jsx(HighlightOffIcon, { fontSize: "small" }) }) }) }))
            } }) }));
};
