import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
export var AppMenuItem = function (_a) {
    var onClick = _a.onClick, href = _a.href, buttonSx = _a.buttonSx, primaryText = _a.primaryText, secondaryText = _a.secondaryText, icon = _a.icon;
    var content = (_jsxs(_Fragment, { children: [_jsx(ListItemIcon, { sx: {
                    minWidth: 0
                }, children: icon }), _jsx(ListItemText, { primary: primaryText, secondary: secondaryText, sx: {
                    ml: 4,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                } })] }));
    return (_jsx(ListItem, { disablePadding: true, sx: { display: 'block' }, children: onClick ? (_jsx(ListItemButton, { sx: buttonSx, onClick: onClick, children: content })) : (_jsx(ListItemButton, { target: "_blank", href: href ? href : '', sx: buttonSx, children: content })) }));
};
