import { jsx as _jsx } from "react/jsx-runtime";
import 'react-toastify/dist/ReactToastify.css';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Flip, toast, ToastContainer } from 'react-toastify';
export var notification = {
    error: function (message) {
        toast.error(message, {
            icon: function () { return _jsx(ErrorOutlineIcon, {}); },
            style: {
                backgroundColor: "#e53800",
                color: 'white',
                fontFamily: 'Raleway'
            }
        });
    },
    success: function (message) {
        toast.success(message, {
            icon: function () { return _jsx(TaskAltIcon, {}); },
            style: {
                backgroundColor: "#2D7B01",
                color: 'white',
                fontFamily: 'Raleway'
            }
        });
    }
};
export var NotificationContainer = function () { return (_jsx(ToastContainer, { style: {
        left: '3.75rem'
    }, icon: false, position: "bottom-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: true, theme: "colored", transition: Flip })); };
