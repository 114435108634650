export var ROLE_MAPPING = {
    'pricer-admin': [
        'tag:create',
        'tag:update',
        'tenant:read',
        'connection:update',
        'connection:create',
        'connection:delete',
        'user:create',
        'user:update',
        'user:read',
        'user:delete',
        'pricer:employee',
        'access-key:create',
        'access-key:read',
        'access-key:delete',
        'access-key:update',
        'pricer:admin'
    ],
    'pricer-editor': [
        'tag:create',
        'tag:update',
        'tenant:read',
        'connection:update',
        'connection:create',
        'pricer:employee'
    ],
    'pricer-viewer': [
        'pricer-viewer:only',
        'pricer-profile:only',
        'pricer:employee'
    ],
    'pricer-profile': [],
    'super-user': [
        'tenant:read',
        'connection:update',
        'connection:create',
        'connection:delete',
        'access-key:create',
        'access-key:read',
        'access-key:delete',
        'access-key:update',
        'pricer:employee'
    ],
    'business-admin': ['user:read', 'access-key:read', 'roles:read'],
    'store-manager': ['user:read', 'user:update', 'roles:read'],
    designer: [],
    'store-worker': [],
    'tenant-admin': [
        'user:read',
        'user:update',
        'user:create',
        'user:delete',
        'tag:create',
        'tag:update',
        'access-key:create',
        'access-key:read',
        'access-key:delete',
        'access-key:update',
        'roles:read'
    ]
};
