var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { baseApi, responseHandler, transformPaginatedResponse } from './api';
export var isAccessDenied = function (error) { return [401, 403].includes(getApiStatusCode(error)); };
export function getApiStatusCode(error) {
    if (!error) {
        return 0;
    }
    var fetchError = error;
    if (fetchError.status === undefined) {
        return -1;
    }
    if (typeof fetchError.status === 'number') {
        return fetchError.status;
    }
    if (fetchError.status === 'PARSING_ERROR') {
        return fetchError.originalStatus;
    }
    return -1;
}
var _adminApi = baseApi.enhanceEndpoints({
    addTagTypes: ['AdminUsers', 'AdminUser']
});
export var adminApi = _adminApi.injectEndpoints({
    endpoints: function (build) { return ({
        testExample: build.query({
            query: function () { return 'api/v1/admin/test'; }
        }),
        getAdminUsersList: build.query({
            query: function (_a) {
                var page = _a.page, size = _a.size, search = _a.search;
                return ({
                    url: "api/v1/admin/users",
                    params: { page: page, size: size, search: search },
                    responseHandler: function (response) { return responseHandler(response, 'Users'); }
                });
            },
            providesTags: function (result) {
                return result
                    ? __spreadArray(__spreadArray([], result.data.map(function (_a) {
                        var id = _a.id;
                        return ({
                            type: 'AdminUsers',
                            id: id
                        });
                    }), true), [
                        { type: 'AdminUsers', id: 'PARTIAL-LIST' }
                    ], false) : [{ type: 'AdminUsers', id: 'PARTIAL-LIST' }];
            },
            transformResponse: (transformPaginatedResponse)
        }),
        getUserAdmin: build.query({
            query: function (id) { return ({
                url: "api/v1/admin/users/".concat(id),
                responseHandler: function (response) { return responseHandler(response, 'User'); }
            }); },
            providesTags: function (_result, _error, id) { return [{ type: 'AdminUser', id: id }]; }
        }),
        saveUserAdmin: build.mutation({
            query: function (user) { return ({
                url: "api/v1/admin/users/".concat(user.id),
                method: 'PUT',
                body: __assign(__assign({}, user), { id: undefined, email: undefined, username: undefined, name: undefined }),
                responseHandler: function (response) { return responseHandler(response, 'Update User'); }
            }); },
            invalidatesTags: function (_result, _error, _a) {
                var id = _a.id;
                return [
                    { type: 'AdminUser', id: id },
                    { type: 'AdminUsers' }
                ];
            }
        }),
        createUserAdmin: build.mutation({
            query: function (user) { return ({
                url: 'api/v1/admin/users',
                method: 'POST',
                body: __assign({}, user),
                responseHandler: function (response) { return responseHandler(response, 'Create User'); }
            }); },
            invalidatesTags: ['AdminUsers', 'AdminUser']
        }),
        patchUserAdmin: build.mutation({
            query: function (user) { return ({
                url: "api/v1/admin/users/".concat(user.id),
                method: 'PATCH',
                body: {
                    password: user.password
                },
                responseHandler: function (response) { return responseHandler(response, 'Update User'); }
            }); }
        }),
        deleteUserAdmin: build.mutation({
            query: function (id) { return ({
                url: "api/v1/admin/users/".concat(id),
                method: 'DELETE',
                responseHandler: function (response) { return responseHandler(response, 'Delete User'); }
            }); },
            invalidatesTags: ['AdminUsers']
        })
    }); },
    overrideExisting: false
});
export var useTestExampleQuery = adminApi.useTestExampleQuery, useGetUserAdminQuery = adminApi.useGetUserAdminQuery, useSaveUserAdminMutation = adminApi.useSaveUserAdminMutation, useCreateUserAdminMutation = adminApi.useCreateUserAdminMutation, useGetAdminUsersListQuery = adminApi.useGetAdminUsersListQuery, useDeleteUserAdminMutation = adminApi.useDeleteUserAdminMutation, usePatchUserAdminMutation = adminApi.usePatchUserAdminMutation;
