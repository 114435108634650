var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler, transformPaginatedResponse } from './api';
export var tagApi = createApi({
    reducerPath: 'tagApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().tagApi,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    tagTypes: ['Tag', 'Tags', 'Entity', 'Entities'],
    endpoints: function (builder) { return ({
        getTag: builder.query({
            query: function (id) { return ({
                url: "api/v1/tags/".concat(id),
                responseHandler: function (response) { return responseHandler(response, 'Tag'); }
            }); },
            providesTags: function (_result, _error, id) { return [{ type: 'Tag', id: id }]; }
        }),
        getTags: builder.query({
            query: function (filters) { return ({
                url: 'api/v1/tags',
                params: filters,
                responseHandler: function (response) { return responseHandler(response, 'Tags'); }
            }); },
            providesTags: function (result) {
                return result
                    ? __spreadArray(__spreadArray([], result.data.map(function (_a) {
                        var id = _a.id;
                        return ({ type: 'Tags', id: id });
                    }), true), [
                        { type: 'Tags', id: 'PARTIAL-LIST' }
                    ], false) : [{ type: 'Tags', id: 'PARTIAL-LIST' }];
            },
            transformResponse: (transformPaginatedResponse)
        }),
        saveTag: builder.mutation({
            query: function (tag) { return ({
                url: "api/v1/tags/".concat(tag.id),
                method: 'PATCH',
                body: {
                    entities: tag.entities
                },
                responseHandler: function (response) { return responseHandler(response, 'Update Tag'); }
            }); },
            invalidatesTags: function (_result, _error, savedTag) { return [
                { type: 'Tag', id: savedTag.id },
                { type: 'Tags' },
                { type: 'Entity' },
                { type: 'Entities' }
            ]; }
        }),
        createTag: builder.mutation({
            query: function (tag) { return ({
                url: 'api/v1/tags',
                method: 'POST',
                body: {
                    tag: tag.tag,
                    locked: false,
                    entities: tag.entities
                },
                responseHandler: function (response) { return responseHandler(response, 'Create Tag'); }
            }); },
            invalidatesTags: function () { return [
                { type: 'Tags' },
                { type: 'Entity' },
                { type: 'Entities' }
            ]; }
        }),
        deleteTag: builder.mutation({
            query: function (id) { return ({
                url: "api/v1/tags/".concat(id),
                method: 'DELETE',
                responseHandler: function (response) { return responseHandler(response, 'Delete Tag'); }
            }); },
            invalidatesTags: function (_result, _error, id) { return [
                { type: 'Tag', id: id },
                { type: 'Tags' },
                { type: 'Entity' },
                { type: 'Entities' }
            ]; }
        })
    }); }
});
export var useGetTagQuery = tagApi.useGetTagQuery, useGetTagsQuery = tagApi.useGetTagsQuery, useLazyGetTagsQuery = tagApi.useLazyGetTagsQuery, useCreateTagMutation = tagApi.useCreateTagMutation, useSaveTagMutation = tagApi.useSaveTagMutation, useDeleteTagMutation = tagApi.useDeleteTagMutation;
