var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler, transformPaginatedResponse } from './api';
export var storeApi = createApi({
    reducerPath: 'storeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().storeApi,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    tagTypes: ['Stores'],
    endpoints: function (builder) { return ({
        getStore: builder.query({
            query: function (id) { return ({
                url: "api/v1/stores/".concat(id),
                responseHandler: function (response) { return responseHandler(response, 'Store'); }
            }); },
            providesTags: function (_result, _error, id) { return [{ type: 'Stores', id: id }]; }
        }),
        getStores: builder.query({
            query: function (_a) {
                var _b = _a.size, size = _b === void 0 ? 10 : _b, rest = __rest(_a, ["size"]);
                return ({
                    url: "api/v3/stores",
                    params: __assign({ size: size }, rest),
                    responseHandler: function (response) { return responseHandler(response, 'Stores'); }
                });
            },
            providesTags: function (result) {
                return result
                    ? __spreadArray(__spreadArray([], result.data.map(function (_a) {
                        var id = _a.id;
                        return ({ type: 'Stores', id: id });
                    }), true), [
                        { type: 'Stores', id: 'PARTIAL-LIST' }
                    ], false) : [{ type: 'Stores', id: 'PARTIAL-LIST' }];
            },
            transformResponse: (transformPaginatedResponse)
        })
    }); }
});
export var useGetStoreQuery = storeApi.useGetStoreQuery, useGetStoresQuery = storeApi.useGetStoresQuery, useLazyGetStoresQuery = storeApi.useLazyGetStoresQuery;
